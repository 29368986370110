@media only screen and (max-width: 1450px) {
  .section.flex.products .fifth {
    padding-top: 70px;
    background-color: #fff;
    position: fixed;
    left: 20px;
    top: 35px;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    transform: translate3d(-100vw, 0, 0);

    overflow: scroll;
    width: 70vw;
    height: 100vh;
  }

  .section.flex.products .fifth.open {
    transform: translate3d(0vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    width: 100vw;
    height: 100vh;
  }

  .section.flex.products .four-fifths {
    width: 100%;
  }

  .products #toggle-filter {
    display: flex;
  }
}

@media only screen and (max-width: 1100px) {
  .section.flex.products .card-cont {
    width: 98%;
    margin: 1% 1%;
  }

  .section.flex .card.pcn {
    width: 98%;
    margin: 1% 1%;
  }
}

@media only screen and (max-width: 767px) {
  #zubehoer-tools .card-cont.fourth {
    width: 100%;
    margin: 1.5% 0;
  }

  .card img.news-pic {
    width: 162px;
  }

  .hero-content {
    left: 20px;
  }

  div.card.news {
    width: 250px;
  }

  h2.news-header {
    font-size: 32px;
  }

  div.card.news span.news-desc {
    font-size: 14px;
    line-height: 18px;
  }

  h4.card-title.news {
    font-size: 18px;
  }

  .react-pdf__Page__canvas,
  .react-pdf__Page__textContent {
    margin: 0 auto;
    width: 100% !important;
    height: auto !important;
  }

  div.small .card-desc {
    width: 50%;
  }

  div.flex.spb.hfg {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .section.flex.products .fifth {
    top: 0;
  }

  #products .card {
    flex-direction: column;
  }

  #products .card-desc {
    width: 33.3333%;
  }

  #products .card-divider {
    width: 100px;
    height: 2px;
    margin: 20px 0;
  }

  #products .part-30,
  #products .part-70 {
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  #products .card-desc {
    width: 100%;
  }
}

div.card.news .news-desc {
  line-height: 24px;
}

#filter-cont-fifth .hidden {
  display: none;
}

#toggle-filter {
  position: fixed;
  right: 10px;
  bottom: 10px;
  display: none;
  z-index: 10;
  color: white;
  background-color: #d6112b;
  height: 32px;
  width: 32px;
  padding: 10px;
  border-radius: 100%;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
  height: 100%;
}

.card-cont-2 {
  max-width: 1000px;
}

.card.news {
  background-color: white;
  border-radius: 10px;
  margin-left: 20px;
  padding: 20px;
  height: 100%;
  flex-direction: column;
  width: 500px;
  justify-content: start;
  overflow: auto;
}

.card.news.active {
  display: flex;
}

.card.news h4 {
  margin: 20px 0;
}

.card.news img {
  margin-bottom: 20px;
}

.card.small {
  flex-wrap: wrap;
  justify-content: left;
}

#products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.card-cont {
  text-decoration: none;
  height: 100%;
  width: 48%;
  margin: 1% 1%;
  color: #333;
}

.card.pcn {
  width: 48%;
  margin: 0.9%;
  padding: 0;
}

.card.pcn .card-left {
  padding: 10px 10px;
}

.card-desc.pcn {
  width: 100%;
}

.card.pcn .card-right {
  padding: 10px;
}

.card-cont.fourth {
  width: 23%;
}

#zubehoer-tools .card-left {
  width: 100%;
}

#zubehoer-tools .card-left.pcn {
  width: 30%;
}

.card-cont.small {
  width: 100%;
  margin: 20px 0 0 0;
}

.card-cont:hover {
  color: #d6112b;
}

.pcn .card-title {
  padding: 0 10px;
}

.card-title {
  margin-top: 10px;
  text-align: center;
}

h4.card-title.news {
  margin-top: 0;
}

.card-divider {
  height: 100px;
  width: 2px;
  background-color: gainsboro;
  margin-right: 20px;
}

.card.pcn .card-divider {
  margin-right: 0;
}

img.news-pic {
  width: 256px;
}

.card-desc {
  margin: 10px 0;
  color: #333;
  width: 50%;
}

.small .card-desc {
  width: 16.66666%;
}

.card-right {
  flex-wrap: wrap;
}

span.prod-attr {
  display: block;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

span.prod-attr-desc {
  display: block;
  font-size: 14px;
  text-align: center;
}

h3 {
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 400;
}

h3.filter-header {
  color: #d6112b;
}

.search-input-filter,
.search-input-filter:focus-visible {
  border-radius: 50px;
  border: 1px solid rgba(51, 51, 51, 0.2);
  padding: 9px 20px;
  width: 150;
  font-size: 14px;
  outline: none !important;
}

#pagination-control {
  margin-top: 40px;
}

#pagination-control button {
  margin: 0 20px;
}

.bottom-divider {
  width: 94%;
  height: 1px;
  margin-top: 20px;
  background-color: #ccc;
}

.product-p #navigation_controls h2,
.product-p #navigation_controls p {
  text-align: center;
}

.checkbox-label {
  padding-bottom: 2px;
}

.checkbox-cont {
  margin-bottom: 5px;
}

.spinner {
  height: 120px;
}

.tool-img {
  width: 100%;
}

.tool-img img {
  max-width: 256px;
}

p.body.count {
  font-size: 28px;
  height: 120px;
  display: flex;
  align-items: center;
}

#spinner {
  width: 100px;
}

.filter-button {
  font-size: 17px;
  display: inline-block;
  width: 46%;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 0px;
  box-shadow: 1px 1px 3px 0 rgb(0 0 0 / 10%);
  color: #2f2d38;
  text-align: center;
  text-decoration: none;
  background-color: white;
  margin-right: 2%;
  margin-bottom: 10px;
}

.filter-button.active {
  background-color: #d6112b;
  color: white;
}