@font-face {
  font-family: "Lato Regular";
  src: url("./fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato Light";
  src: url("./fonts/Lato/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato Light Italic";
  src: url("./fonts/Lato/Lato-LightItalic.ttf");
}

@media only screen and (max-width: 767px) {
  .content .hero {
    margin-top: 40px;
  }

  .news-container {
    max-height: calc(70vh - 78px);
  }

  .hero.home .hero-content {
    top: calc(78px);
  }

  .hero.home .hero-content-home {
    left: 20px;
  }

  .content h1 {
    font-size: 32px;
  }

  .medium .heading1-cont h2 {
    font-size: 36px;
  }

  .content .hero-content .hero-heading {
    font-size: 36px;
  }

  .content .hero-content .hero-desc {
    font-size: 24px;
  }

  .content .section {
    margin-bottom: 20px;
  }

  .section .half,
  .section .third {
    width: 100%;
    margin-bottom: 20px;
  }

  .section .third-container.third {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .third-container img {
    width: 300px;
  }

  .section .third.third-container {
    margin-bottom: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {}

div.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

body {
  margin: 0;
  font-family: "Lato Regular";
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex.center-h {
  justify-content: center;
}

.flex.center-v {
  align-items: center;
}

.flex.row {
  flex-direction: row;
}

.flex.col {
  flex-direction: column;
}

.flex.spb {
  justify-content: space-between;
}

.flex.spa {
  justify-content: space-around;
}

.flex.spa {
  justify-content: space-evenly;
}

.flex.left {
  justify-content: left;
}

.flex.right {
  justify-content: right;
}

.img-full {
  width: 100%;
}

.third-container {
  padding: 10px;
}

.third-container.third {
  width: 31.33333%;
  padding: 0 1%;
}

.section {
  padding: 20px;
  margin-bottom: 80px;
}

.section.np {
  padding: 20px;
  margin-bottom: 0px;
}

.section .medium {
  max-width: 1200px;
  width: 100%;
}

.section.full {
  max-width: 100%;
}

h4 {
  margin: 20px 0 10px 0;
  font-size: 24px;
}

.bg-quote {
  color: #fff;
  font-size: 42px;
  line-height: 1.3;
  font-weight: lighter;
  text-align: center;
  max-width: 1200px;
  font-family: "Lato Light Italic";
}

a {
  text-decoration: none;
  color: #333;
}

.product-p img.product-img {
  width: 100%;
  height: auto;
  max-width: 500px;
  margin-bottom: 40px;
}

a.accented {
  color: #d6112b;
}

a.accented:hover {
  text-decoration: underline;
}

a:hover {
  color: #d6112b;
  cursor: pointer;
}

.half {
  width: 50%;
}

.third {
  width: 33.33333%;
}

.fourth {
  width: 25%;
}

.two-thirds {
  width: 66.666666%;
}

.two-thirds.home {
  width: 100%;
}

.part-40 {
  width: 40%;
}

.part-30 {
  width: 30%;
}

.part-60 {
  width: 60%;
}

.part-70 {
  width: 70%;
}

.quart {
  width: 25%;
}

.tenth {
  width: 10%;
}

.part-45 {
  width: 45%;
}

.part-45.news-part {
  width: auto;
}

.part-45.news-part img {
  margin-right: 10px;
}

.fifth {
  width: 20%;
}

.four-fifths {
  width: 80%;
}

.accent,
.active {
  color: #d6112b;
}

.m-left-1 {
  margin-left: 5px;
}

.m-right-1 {
  margin-right: 5px;
}

.m-left-2 {
  margin-left: 10px;
}

.full-width {
  width: 100%;
}

.m-right-2 {
  margin-right: 10px;
}

.m-left-3 {
  margin-left: 20px;
}

.m-right-3 {
  margin-right: 20px;
}

.m-bottom-3 {
  margin-bottom: 20px;
}

.m-top-4 {
  margin-top: 35px;
}

.m-top-2 {
  margin-top: 20px;
}


nav {
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 20%);
}

.section.first {
  margin-top: 30px;
}

.heading-deco {
  width: 50px;
  height: 4px;
  margin-top: 6px;
  background-color: #d6112b;
}

h1 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
  text-align: center;
}

.heading1-cont {
  width: 100%;
}

.heading1-cont.news-h {
  width: 100%;
  margin-left: 20px;
}

.heading1-cont.news-h h2 {
  color: white;
}

.heading1-cont.news-h div {
  background-color: white;
}

.heading1-cont h2 {
  font-size: 48px;
  line-height: 1.2;
  font-weight: 400;
}

button.default,
a.default {
  font-family: "Lato Regular";
  font-size: 17px;
  padding: 15px 25px;
  color: white;
  text-align: center;
  text-decoration: none;
  background-color: #d6112b;
  border: none;
  cursor: pointer;
}

p.body.s-18 {
  font-size: 18px;
}

.medium a.default.diff-1 {
  padding: 20px 30px;
  font-size: 20px;
}

.eye-catcher.accent {
  margin-bottom: 20px;
}

ul.body {
  font-size: 20px;
  line-height: 1.5;
  padding: 0 20px;
}

h2.alternate-heading {
  color: #d6112b;
  font-weight: light;
  padding: 0 10px;
}

.half.red-bg h2 {
  font-size: 28px;
}

div.half div.half.red-bg h4 {
  font-size: 24px;
}

p.body.inverted {
  color: white;
  padding: 0 20px;
  font-size: 20px;
}

p.body.padding-default {
  padding: 0 10px;
  margin-top: -30px;
  margin-bottom: 20px;
  font-size: 18px;
}

button.default.not-active {
  font-size: 17px;
  padding: 15px 25px;
  color: #333;
  text-align: center;
  text-decoration: none;
  background-color: gainsboro;
  border: none;
  cursor: pointer;
}

button.default.inverted {
  background-color: white;
  color: #d6112b;
}

h4.news-archive-header {
  text-align: center;
  min-height: 40px;
  margin-bottom: 0;
}

.sonstiges a, .sonstiges-2 a {
  margin-right: 10px;
}

.sonstiges-2 {
  margin-top: 20px;
}

h5.news-archive-subheader {
  margin-top: 10px;
}

.v-end {
  align-items: end;
}

.checkbox {
  margin-top: 7px;
  margin-right: 5px;
}

.checkbox-cont {
  width: 50%;
}

.content img {
  max-width: 100%;
}

.hero {
  margin-top: -118px;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.red-bg-hero {
  width: 100%;
  height: 100%;
  margin-top: -118px;
  background-image: url("./assets/hero_red_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.7;
  z-index: -1;
}

.hero.ref {
  background-image: url("../src/assets/ref_hero.jpg");
}

.hero-content {
  position: absolute;
  top: calc(2vh + 118px);
  bottom: 2vh;
  padding: 20px 20px 20px 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-content.new {
  width: 50%;
}

.hero-heading-2 {
  color: white;
  font-size: 32px;
  font-weight: lighter;
  opacity: 0.9;
  margin-top: 0;
}

.hero-content-home {
  position: absolute;
  top: calc(2vh + 118px);
  bottom: 2vh;
  padding: 20px 20px 20px 0;
  left: calc(50% - 300px);
}

/* .hero-content {
  left: 19%;
  max-width: 60%;
  position: absolute;
  top: 45%;
} */

.news-container {
  flex-wrap: nowrap;
  max-height: calc(70vh - 118px);
}

.hero-content .hero-heading {
  color: #fff;
  font-size: 50px;
  line-height: 1.3;
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
}

.hero-content .hero-desc {
  color: #fff;
  font-size: 26px;
  line-height: 1.3;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.hero-content .hero-desc.new {
  line-height: 1.5;
  font-size: 24px;
}

p.body {
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}

p.body.center {
  text-align: center;
}

body pre {
  font-family: "Lato Regular";
  color: #333;
  font-size: 17px;
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}